import twogb from '../images/2gb.png'
import tengb from '../images/10GB.png'
import iphone12 from '../images/iphone12.webp'
import iwatch from '../images/iwatch.jpeg'
import _2545recharge from '../images/2545.png'
import _758cash from '../images/758cash.png'
import _500cash from '../images/500cash.jpeg'


export const prizes=[
    {
        'img':twogb,
        'text':'You won 2gb free jio Data'
    },
    {
        'img':tengb,
        'text':'You won 10gb free jio Data'
    },
    {
        'img':iphone12,
        'text':'You won Iphone 12 '
    },
    {
        'img':iwatch,
        'text':'You won Apple watch'
    },
    {
        'img':_2545recharge,
        'text':'You won Rs. 2545 Recharge'
    },
    {
        'img':_758cash,
        'text':'You won 758 Cash prize'
    },
    {
        'img':_500cash,
        'text':'You won 500 Cash Prize'
    },
]