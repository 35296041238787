import React, { useState, useEffect } from 'react';
import search from '../icons/search.png'
import '../style/slider.css'
import { Link } from 'react-router-dom'

import quiz from '../icons/quiz.png'
import notespic from '../images/scretch.jpeg'
import assignmentp from '../images/wheel1.jpeg'
import wheel2 from '../images/wheel2.jpeg'
import spinner from '../images/spiner.gif'
import game from '../images/game.png'
import scratch from '../images/scrach.gif'
import winnertag from '../images/winnertag.webp'

export const Searchbar = () => {

    return (
        <>
            <section id='searchbar'>
                <div className="searchbar">

                    <img src={search} alt="" />
                    <input id='searchbarbutton' type="text" placeholder="Jio rewards" />

                </div>
            </section>
        </>
    )
}




const Slider = () => {
    const images = [
        wheel2,
        notespic,
        assignmentp,

    ];
    const [checkSlide, setCheckSlide] = useState(false)

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 5000); // Auto-slide every 3 seconds

        return () => {
            clearInterval(interval);
        };
    }, [currentIndex]);

    return (
        <>

            <div className="image-slider">
                <img src={images[currentIndex]} alt="Slider" />
                <button onClick={prevSlide} className="slider-button prev-button">
                    ←
                </button>
                <button onClick={nextSlide} className="slider-button next-button">
                    →
                </button>
            </div>
        </>
    );
};


const Options = () => {

    return (
        <>
            <section id="options">
                <div className="options">

                    <div className="line2">
                        <ul>
                            <Link to='spintowin'>
                            <li>
                                <span>
                                    <img src={spinner} alt="" />
                                    <p>Win Prize</p>
                                </span>
                            </li>
                            </Link>
                            <li>
                                <span>
                                    <img src={quiz} alt="" />
                                    <p>Quiz</p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img src={game} alt="" />
                                    <p>Play games</p>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img src={scratch} alt="" />
                                    <p>scretch card</p>
                                </span>
                            </li>


                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}


const winners = [
    { name: 'Sanjay Rathore', prize: '2G Data' },
    { name: 'Rohit Sharma', prize: '500 INR Recharge' },
    { name: 'Anjali Mehta', prize: '1000 INR Cash' },
    { name: 'Vikram Singh', prize: 'Mobile Phone' },
    { name: 'Priya Gupta', prize: '1 Year Subscription' },
    { name: 'Amit Kumar', prize: '2000 INR Cash' },
    { name: 'Neha Verma', prize: '3G Data' },
    { name: 'Suresh Raina', prize: '500 INR Recharge' },
    { name: 'Kiran Rao', prize: 'Mobile Phone' },
    { name: 'Radhika Joshi', prize: '4G Data' }
];

const WinnerList = () => {
    return (
        <div id="winnerlist">

            <div className="container">
                <div style={{display:'flex',justifyContent:'center'}}>
                <img src={winnertag} style={{width:'200px'}} alt="" />

                </div>
                <ul className="winner-list">
                    {winners.map((winner, index) => (
                        <li key={index} className="winner-item">
                            <span className="winner-name">{winner.name}</span>
                            <span className="prize">{winner.prize}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};





const Home = () => {
    useEffect(() => {
        document.title = "Home | jio rewards"
    }, [])

    return (
        <>



            <Slider />
            <Options />


            <WinnerList />
        </>
    )
}
export default Home;
