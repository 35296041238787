import React, { useEffect, useState } from 'react';
import { prizes as prizedata } from './arrdata';
import Celebration from "./celebration";

const prizes = [
  '2GB Data', 
  '10GB Data', 
  'Iphone 12', 
  'Apple watch', 
  '1 Year recharge', 
  '758 Cash', 
  '500 Cash', 
  'Opps!'
];

const Spinner = () => {
  const [spinning, setSpinning] = useState(false);
  const [prize, setPrize] = useState('');
  const [rotation, setRotation] = useState(0);
  const [btnvalue, setbtnvalue] = useState('Spin');
  const [count,setCount]=useState(-1)
  const [claim,setClaim]=useState(false)
  const [isprize,setisprize]=useState(true)
  




  const startSpin = () => {
    if(btnvalue=='claim') {
    window.location.href='/claim/'+count;
    return 1
    }
    setSpinning(true);
    setbtnvalue('Spinning..')
    setPrize('');

    const newRotation = rotation + 360 * 5 + Math.floor(Math.random() * 360);
    setRotation(newRotation);

    const prizeIndex = Math.floor((newRotation % 360) / (360 / prizes.length));
    setTimeout(() => {
      setSpinning(false);
      setCount(prizes.length - 1 - prizeIndex)
      if((prizes.length - 1 - prizeIndex)==7){
        setbtnvalue('Retry (left: 9)')
        setisprize(false)
      }
      else{
        setClaim(true)
        setbtnvalue('claim')
        document.querySelector('.pagecontent').classList.add('celebratebg')
      }
      setPrize(prizes[prizes.length - 1 - prizeIndex]);
    }, 5000);
  };

  return (
    <div className="spinner">
    <div className="container">
      <h2 >अंबानी के बेटे की शादी के मौके पर आप जीतेंगे जियो बंपर इनाम!</h2>
      <div className="wheel-container">
        <div
          className="wheel"
          style={{ transform: `rotate(${rotation}deg)` }}
        >
          {prizes.map((prize, index) => (
            <div
              key={index}
              className="wheel-segment"
              style={{
                transform: `rotate(${(360 / prizes.length) * index}deg)`
              }}
            >
              <span 
              
            //   style={{
            //     position: 'absolute',
            //     top: '50%',
            //     left: '60%',
            //     transform: `rotate(${(360 / prizes.length) * -index}deg)`,
            //     transformOrigin: '0 0',
            //     whiteSpace: 'nowrap'
            //   }}

              >
                {prize}
              </span>
            </div>
          ))}
        </div>
      </div>
      {prize && <><div className="prize">{isprize?'Congratulations! You won:':' 🙇‍♀️ '}  {prize}</div></>}
      <button 
        className="button" 
        onClick={startSpin} 
        disabled={spinning}
      >
        {btnvalue}
      </button>
    </div>

{
  claim && (

    <div className="popup">
      <img src={prizedata[count].img} alt="" />
    </div>
  )

}
    
    
    </div>
  );
};

export default Spinner;
