import logo from "./logo.svg";
import "./App.css";
import { useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/style/main.css";
import "./assets/style/style.css";
import "./assets/style/mobile-500.css";
import "./assets/style/animation.css";
import axios from "axios";
import Topmenu, { Search } from "./assets/componants/topmenu";
import More from "./assets/componants/more";
import Home from "./assets/componants/home";
import Spinner from "./assets/componants/spiners";
import ClaimReward from "./assets/componants/claimreward";
import { PrizeProvider, PrizeContext } from './assets/componants/context';

function App() {
  useEffect(() => {
    // Make API call to track visit
    if(localStorage.getItem('oldvisiter')==null){

      axios.get(
        "https://ignoutech.pythonanywhere.com/assets/totalvisit"
      );
      localStorage.setItem('oldvisiter','')
    }
  
  }, []);

  const handleClick = (e) => {
    if (document.body.classList.contains("toggle")) {
      document.body.classList.remove("toggle");
    }
  };
  return (
    <>
    <PrizeProvider>
      <Topmenu />
      <section className="homepartition">
        <div className="pagemenubar tg">
          <More />
        </div>
        <div className="pagecontent" onClick={handleClick}>
          <Routes>
            <Route path="/" Component={Home}></Route>
            <Route path="/more" Component={More}></Route>
            <Route path="/spintowin" Component={Spinner}></Route>
            <Route path="/claim/:id" Component={ClaimReward}></Route>
           
          </Routes>
          {/* <Bottommenu /> */}
        </div>
      </section>
      </PrizeProvider>
    </>
  );
}

export default App;
