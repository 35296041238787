// PrizeContext.js
import React, { createContext, useState } from 'react';

export const PrizeContext = createContext();

export const PrizeProvider = ({ children }) => {
  const [prizeWon, setPrizeWon] = useState(true);

  return (
    <PrizeContext.Provider value={{ prizeWon, setPrizeWon }}>
      {children}
    </PrizeContext.Provider>
  );
};
